import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { GATSBY_HELP_EMAIL } from "gatsby-env-variables"

import Layout from "layout/Layout"
import Container from "layout/Container"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      spilledMeds: file(relativePath: { eq: "pages/404__spilledMeds.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const spilledMeds = data.spilledMeds.childImageSharp.fluid

  return (
    <Layout seoTitle="404: Not found">
      <Container customClassName="my-5" isCentered>
        <Container mobile={10} tablet={8} desktop={6} isCentered>
          <Img fluid={spilledMeds} />
        </Container>
        <center>
          <h2>Something’s wrong here...</h2>
          <p>
            We can’t find the page you entered.
            {/* You may visit our{" "}
            <Link to="/help-center" className="has-text-weight-bold">
              Help Center
            </Link>
            , <br className="is-hidden-mobile" /> or{" "}
            <Link to="/help-center#contact-us" className="has-text-weight-bold">
              contact us
            </Link>{" "}
            for further assistance. */}{" "}
            You may email us at{" "}
            <a
              href={`mailto:${GATSBY_HELP_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
              className="has-text-weight-bold"
            >
              {GATSBY_HELP_EMAIL}
            </a>{" "}
            for further assistance.
          </p>
        </center>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
